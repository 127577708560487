import React from "react";
import { Link } from "gatsby"
import cover from './../content/assets/ukzn-tl-cover-1.jpg'



const Slider = () => {




  return (

    <>
       

        <div className="w-100 h450 h500-m h600-l cf relative overflow-hidden">
            <div className="box box01">
                 <div className="w-100 h450 h500-m h600-l cover dib cover bg-top bg-top-m " style={{backgroundImage: `url(${cover})` }}>
                         <div className="absolute bottom-0 left-0 right-0 ">
                            <div className="w-100 bg-orange-700 center pa1 tc ttu">
                              <Link className="mw-700 textanimate white-100 tc" to="/00-preface/" >
                                <div className="f4 f3-l ls1 fw3 tc ttu dib mr2">Every Student Matters</div>
                                <svg viewBox="0 0 20 20" fill="currentColor" className="arrow-right w2 h2 dib"><path fillRule="evenodd" d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z" clipRule="evenodd" /></svg>
                              </Link>
                              
                            </div>
                        </div>
                </div>
            </div>
        </div>

       


    </>
  );
};

export default Slider