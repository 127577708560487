import React, { useEffect, } from "react"
import Layout from '../components/layout'
import {gsap} from 'gsap'
import Carousel from "../components/carousel"
import SEO from "../components/seo"
import PDF from "./../content/assets/UKZN-T&L-2021-AR-web.pdf"
import COVER from "./../content/assets/ukzn-tl-cover.jpg"
import PDFICON from "./../content/assets/pdf.svg"

export default function Index() {


  useEffect(() => {    
    const tl = gsap.timeline({ repeat:-1, yoyo:true });
    tl.fromTo(".graphic", {opacity:0, transformOrigin:"center center" },{ keyframes: [
        { duration: 15, backgroundSize: "230%"}, 
      ]});  
}, []);


  return (


      <Layout>
               
    
     <SEO title="All posts" />
     <div className="flex flex-column">


     <div className="w-100 cf relative">
      <Carousel />
     </div>

      <div className="w-100 cf bg-gray-100 pv4 pv5-m ph3">
        <div className="w-100 mw-1400 center">
          <div className="flex flex-column flex-row-m mw-900 pointer hover-bg-white-100 pa2 br-1 shadow-hover">
            <img className="mw-200  obj-cover  mr3 mb3" src={COVER} alt="..." />
            <div className="flex flex-column">

            <a className="black link" href={PDF} rel="noopener noreferrer" target="_blank"> 
              <div className="flex flex-row">
              <img className="w2 h2 mr2" src={PDFICON} alt="..." />
              <div className="w-100 f5 fw5 lh-1-25 bb b--black self-end pb1">Read the T&L Report 2020/21 PDF version</div>
              </div>
              <p className="f6 lh-1-5">The University of KwaZulu-Natal has positioned itself as the premier 
              university of African scholarship and the primary responsibility assigned to the UKZN 
              Teaching & Learning Office (UTLO) is to provide leadership in all areas of teaching 
              and learning and institutional research to realise the University's vision, mission and 
              strategic plan with regard to teaching and learning.  </p>
            </a>
              
                </div>
            </div> 
          </div>
        </div>
      </div>
         
 
       
      </Layout>

    
  )
}
